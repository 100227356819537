.header-left {
    height: 100%;
    display: flex;
    align-items: center;
    .dashboard_bar {
        font-size: 24px;
        font-weight: 600;
        color: #000;
    }
}

.search-area {
    width: 230px;
    .form-control {
        min-height: 45px;
        background: rgba(244, 246, 250, 0.8) !important;
        border-width: 1px;
        padding-left: 20px;
        border-radius: 0;
        border-right: 0;
        border-top-left-radius: 1.25rem;
        border-bottom-left-radius: 1.25rem;
    }
    .input-group-append {
        margin-left: -1px;
    }
    .input-group-text {
        background: rgba(244, 246, 250, 0.8);
        border: 1px solid #ced4da;
        border-top-right-radius: 1.25rem !important;
        border-bottom-right-radius: 1.25rem !important;
        padding-left: 0;
        padding-right: 20px;
        border-left: 0;
        min-width: 50px;
        display: flex;
        justify-content: center;
        padding: 0.532rem 0.75rem;
        .btn-primary{
            background-color: transparent;
            border-color: transparent;
        }
    }
}

.header-navbar {
    padding-left: 18rem !important;
    background-color: #fff;
    border-bottom: 1px solid #EEEEEE;
    position: fixed;
    width: calc(100%);
    z-index: 9;
    right: 0;
    margin: 0 !important;
    margin: 0rem 2rem 0;
    border-radius: 0;
    padding: .8rem 1rem;
    li a.nav-link {
        color: #6e6b7b;
        position: relative;
    }
    svg {
        color: #00AEFF;
        width: 1.2rem !important;
        height: 1.2rem !important;
    }
    .navPrice {
        right: 0;
        margin-top: -10px;
        .badge {
            transform: translateY(-4px);
        }
    }
}

.header-right {
    .header-profile {
        button {
            // width: 50px;
            height: 50px;
            background: transparent;
            color: #333;
            outline: none;
            border: 0;
            border-color: transparent !important;
            &:focus {
                background: unset !important;
                box-shadow: unset !important;
                color: #333;
                background-color:transparent;
              }
            &:focus:active {
                background: unset !important;
                box-shadow: unset !important;
                color: #333;
                background-color:transparent;
              }
        }
        .nav-link {
            display: flex;
            align-items: center;
            border-radius: 1.25rem 28px 28px 1.25rem;
        }
        .dropdown-toggle::after{
            display: none;
        }
    }
}



// sidebar
.appCollapsed{
    .header-navbar{
        padding-left: 5rem !important;
    }
    .teckieMain{
        width: 4.5rem !important;
        &:hover{
            width: 16rem !important;
        }
        .logoSection{
            padding: 1rem 0.5rem !important;
            font-size: 1rem;
            text-align: center;
            .logosidebar {
                opacity: 1;
            }
        }
        .teckieMain-scroll{
            .nav{
                .nav-item{
                    .nav-link{
                        .navIcon {
                            font-size: 1.5rem;
                            margin-right: 1.75rem;
                        }
                    }
                }
            }
        }
    }
}
.teckieMain {
    width: 16rem;
    padding-bottom: 0;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 99;
    background-color: #034694;
    box-shadow: 0px 0px 10px #dbdbd9;
    transition: all .2s ease;
    position: fixed;
    .logoSection{
        padding: 1rem 1rem;
        font-size: 1.5rem;
        text-align: center;
    }
    .teckieMain-scroll{
    &::-webkit-scrollbar {
      width: 6px;
      background: #fff;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.3);
    }
    max-height: calc(100vh - 102px);
    position: relative;
    overflow: auto;
    overflow-x: hidden;
        .nav{
            overflow-x: hidden;
            padding-bottom: 0.75rem;
            .nav-item{
                cursor: pointer;
                .nav-link{
                    color: #fff;
                    text-decoration: none;
                    font-size: 1rem;
                    padding: 1rem 1.5rem;
                    .navIcon {
                        font-size: 1.25rem;
                        margin-right: 0.75rem;
                    }
                }
                .active{
                    text-decoration: none;
                    color: #ffb100;
                    font-weight: 600;
                }
            }
        }
    }
}


 
